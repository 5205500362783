import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Layout from "../components/layout"
import SEO from "../components/seo"

const emailParts = ["mail", "@", "interfacer.co.uk"]

class EmailAddress extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shown: false,
      email: "",
    }
    this.show = this.show.bind(this)
  }

  componentWillMount() {
    this.setState({ email: emailParts.join("") })
  }

  show() {
    this.setState({ show: true })
  }

  render() {
    return this.state.show ? (
      <a href={`mailto:${this.state.email}`} className="text-dark">
        {this.state.email}
      </a>
    ) : (
      <Button variant="dark" onClick={this.show}>
        Email us
      </Button>
    )
  }
}

const TalkToUsPage = () => (
  <Layout>
    <SEO title="Talk to us" />
    <Container
      fluid={true}
      style={{
        paddingTop: "50px",
        paddingBottom: "50px",
        backgroundColor: "#8fc1e3",
      }}
    >
      <Container>
        <Row>
          <Col sm={12} md={8}>
            <h1>Talk to us</h1>
            <p>
              If you want us to build something for you, or if we can help you
              to build something, then please contact us using the button below.
            </p>
            <EmailAddress />
          </Col>
        </Row>
      </Container>
    </Container>
  </Layout>
)

export default TalkToUsPage
